var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_c('h3',[_vm._v(_vm._s(_vm.$t('doubleAuth.EditSettings')))])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancelAndClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('h3',{staticClass:"mx-4 mt-4"},[_vm._v(_vm._s(_vm.$t('doubleAuth.EditConfiguration')))]),_c('v-form'),_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":_vm.$t('doubleAuth.ConfigurationTitle'),"rules":[_vm.titleRules]},model:{value:(_vm.creatingConfiguration.double_authentification.data.title),callback:function ($$v) {_vm.$set(_vm.creatingConfiguration.double_authentification.data, "title", $$v)},expression:"creatingConfiguration.double_authentification.data.title"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.clientList,"item-text":"name","item-value":"name","label":_vm.$t('doubleAuth.client'),"search-input":_vm.search,"rules":[_vm.clientRules]},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.updateSelectedClientBases},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.creatingConfiguration.double_authentification.data.client),callback:function ($$v) {_vm.$set(_vm.creatingConfiguration.double_authentification.data, "client", $$v)},expression:"creatingConfiguration.double_authentification.data.client"}}),(_vm.creatingConfiguration.double_authentification.data.client)?_c('v-autocomplete',{attrs:{"items":_vm.baseList,"label":_vm.$t('doubleAuth.SubBases'),"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}],null,false,3864776252),model:{value:(_vm.selectedClientBases),callback:function ($$v) {_vm.selectedClientBases=$$v},expression:"selectedClientBases"}}):_vm._e(),_c('v-checkbox',{attrs:{"label":_vm.$t('doubleAuth.twoFactorAuth')},model:{value:(_vm.creatingConfiguration.double_authentification.data.twoFactorAuth),callback:function ($$v) {_vm.$set(_vm.creatingConfiguration.double_authentification.data, "twoFactorAuth", $$v)},expression:"creatingConfiguration.double_authentification.data.twoFactorAuth"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.twoFactorAuthTypes,"label":_vm.$t('doubleAuth.AuthType'),"rules":[_vm.authTypeRules]},model:{value:(_vm.creatingConfiguration.double_authentification.data.twoFactorAuthType),callback:function ($$v) {_vm.$set(_vm.creatingConfiguration.double_authentification.data, "twoFactorAuthType", $$v)},expression:"creatingConfiguration.double_authentification.data.twoFactorAuthType"}}),(_vm.creatingConfiguration.double_authentification.data.twoFactorAuthType === 'Email')?_c('v-textarea',{attrs:{"rows":"2","label":_vm.$t('doubleAuth.SetEmail')},model:{value:(_vm.creatingConfiguration.double_authentification.data.msg),callback:function ($$v) {_vm.$set(_vm.creatingConfiguration.double_authentification.data, "msg", $$v)},expression:"creatingConfiguration.double_authentification.data.msg"}},[_vm._v(" "+_vm._s(_vm.creatingConfiguration.double_authentification.data.msg)+" ")]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancelAndClose}},[_vm._v(_vm._s(_vm.$t('actions.cancel')))]),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.isFormValid},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('actions.save')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }