<template>
  <v-container fluid>
    <v-toolbar rounded>
      <span class="text-h5" elevation="20">{{ $t('doubleAuth.configuration') }}</span>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="openModalConfig" color="primary" small> {{ $t('doubleAuth.créer') }}</v-btn>
        </template>
        <span>{{ $t('doubleAuth.CreateConfiguration') }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card class="mt-4 elevation-5">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('actions.search')" single-line
          hide-details></v-text-field>
      </v-card-title>
      <v-data-table 
        :headers="headers"
        :items="configList" 
        :search="search"
        :items-per-page="10"
        class="elevation-1"
        :footer-props="{
        'items-per-page-text': $t('doubleAuth.rows-per-page'),
        'items-per-page-all-text': $t('doubleAuth.all'),
        }"
        >
        <!-- traduction des headers --> 
        <template v-slot:header.double_authentification.data.client="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template> 
        <template v-slot:header.double_authentification.data.title="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template>
        <template v-slot:header.double_authentification.data.bases="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template>
        <template v-slot:header.double_authentification.data.twoFactorAuthType="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template>
        <template v-slot:header.createdAt="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template>
        <template v-slot:header.double_authentification.data.twoFactorAuth="{ header }">
          {{ $t(`doubleAuth.${header.text}`) }}
        </template>
        

        <!-- client -->
        <template v-slot:item.double_authentification.data.client="{ item }">
          {{ formatTextFirstUp(item.double_authentification.data.client) }}
        </template>

        <!-- titre -->
        <template v-slot:item.double_authentification.data.title="{ item }">
          {{ formatTextFirstUp(item.double_authentification.data.title) }}
        </template>

        <!-- listes des bases selectionnée -->
        <template v-slot:item.double_authentification.data.bases="{ item }">
          <v-menu :close-on-content-click="false" offset-y max-height="400">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <template
                  v-if="item.double_authentification.data.bases && item.double_authentification.data.bases.length > 1">
                  {{ $t('doubleAuth.baseSelected') }} {{ item.double_authentification.data.bases.length }}
                </template>
                <template
                  v-else-if="item.double_authentification.data.bases && item.double_authentification.data.bases.length === 1">
                  {{ item.double_authentification.data.bases[0] }}
                </template>
                <template v-else>
                  {{ $t('doubleAuth.NoBaseSelected')  }}
                </template>
              </div>
            </template>
              <v-toolbar v-if="item.double_authentification.data.bases && item.double_authentification.data.bases.length > 1"
                color="primary"
                dark
              >
                <v-text-field
                  v-model="searchBases" 
                  label="Rechercher une base" 
                  hide-details
                  single-line
                  append-icon="mdi-magnify"
                >
                </v-text-field>
              </v-toolbar>
              <v-list>
                <!-- Ajoutez une liste filtrée selon la recherche -->
                <v-list-item v-for="(base, index) in filteredBases(item.double_authentification.data.bases)" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ base }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>

        <template v-slot:item.double_authentification.data.twoFactorAuthType="{ item }">
          <v-icon small color="green" class="mr-2"
            v-if="item.double_authentification.data.twoFactorAuthType === 'SMS'">mdi-message-text</v-icon>
          <v-icon small color="green" class="mr-2" v-if="item.double_authentification.data.twoFactorAuthType ===
            'Email'">mdi-gmail</v-icon>
          <v-icon small color="green" class="mr-2" v-if="item.double_authentification.data.twoFactorAuthType ===
            'Cascade'">mdi-chart-timeline-variant-shimmer</v-icon>

          {{ item.double_authentification.data.twoFactorAuthType }}

          <!-- si aucun choix selectionné -->
          <span v-if="!item.double_authentification.data.twoFactorAuthType">
            <v-icon small color="warning" class="mr-2">mdi-alert-circle</v-icon>
            {{ $t('doubleAuth.noChoiceSelected') }}
          </span>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <v-icon small color="primary" class="mr-2">mdi-calendar-multiple-check</v-icon>
          {{ item.createdAt | formatDate }}
        </template>

        <template v-slot:item.double_authentification.data.twoFactorAuth="{ item }">
          <v-switch v-if="item.double_authentification.data.twoFactorAuth" class="ma-0"
            v-model="item.double_authentification.data.twoFactorAuth" color="success" @change="onSwitchChange(item._id)"
            hide-details dense :label="$t('doubleAuth.actif')" ></v-switch>
          <v-switch v-else class="ma-0" v-model="item.double_authentification.data.twoFactorAuth" color="primary"
            @change="onSwitchChange(item._id)" hide-details dense :label="$t('doubleAuth.inactif')" ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="openModalUpdate(item._id)">mdi-pencil</v-icon>
          <v-icon small color="red" @click="openModalDelete(item._id)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogConfig" max-width="60%">
      <ConfigAuthentification @close-modal="closeModal" @refresh="fetchConfigList" />
    </v-dialog>

    <v-dialog v-model="updateConfig" max-width="60%">
      <UpdateConfig @close-modal="closeModalUpdate" @refresh="fetchConfigList"/>
    </v-dialog>

    <v-dialog v-model="alertDelete" max-width="25%">
      <DeleteConfig @close-modal="closeModalDelete" @refresh="fetchConfigList"/>
    </v-dialog>
  </v-container>
</template>

<script>

import ConfigAuthentification from "./ConfigAuthentification.vue";
import AuthSettings from "@/Services/DoubleAuthentification.js"
import DeleteConfig from "./DeleteConfig.vue";
import UpdateConfig from "./UpdateConfig.vue";
import * as Vuex from "vuex";
import moment from "moment";
import io from "socket.io-client";


export default {
  name: "Authentification",
  components: {
    ConfigAuthentification,
    DeleteConfig,
    UpdateConfig
  },
  computed: {
    ...Vuex.mapMutations(["setConfigList", "setSelectedConfig"]),
    ...Vuex.mapState(["configList"]),
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
  data() {
    return {
      dialogConfig: false,
      alertDelete: false,
      updateConfig: false,
      search: '',
      searchBases: '',
      headers: [
        { text: 'client', value: "double_authentification.data.client"},
        { text: 'titre', value: "double_authentification.data.title" },
        { text: "bases", value: "double_authentification.data.bases" },
        { text: "AuthType", value: "double_authentification.data.twoFactorAuthType" },
        { text: "createdAt", value: "createdAt" },
        { text: "twoFactorAuth", value: "double_authentification.data.twoFactorAuth" },
        { text: "actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    openModalConfig() {
      this.dialogConfig = true;
    },
    closeModal() {
      this.dialogConfig = false;
    },
    openModalDelete(id) {
      this.$store.commit("setSelectedConfig", id);
      this.alertDelete = true;
    },
    closeModalDelete() {
      this.alertDelete = false;
    },
    openModalUpdate(id) {
      this.$store.commit("setSelectedConfig", id);
      this.updateConfig = true;
    },
    closeModalUpdate() {
      this.updateConfig = false;
    },
    formatTextFirstUp(textContent) {
      // Votre logique pour formater la première lettre en majuscule et le reste en minuscules
      return textContent.charAt(0).toUpperCase() + textContent.slice(1).toLowerCase();
    },
    filteredBases(bases) {
    return bases.filter(base => base.toLowerCase().includes(this.searchBases.toLowerCase()));
  },
    // connecte le socket
    connectSocketIO() {
      // Définir l'URL du serveur WebSocket en fonction de l'hôte
      let url = location.host.includes('localhost') ? 'http://127.0.0.1:8182' : `${location.protocol}//${location.host}`;

      // Configuration des paramètres pour la connexion Socket.IO
      let params = {
        transports: ['websocket', 'polling'],
        path: location.host.includes('localhost') ? '' : '/planete-online-sockets/socket.io',
        query: {
          base: this.host,
        },
      };

      // Ajouter des en-têtes supplémentaires si l'hôte est défini
      if (this.host) {
        params.extraHeaders = {
          'base-dev': this.host,
        };
      }

      const socket = io(url, params);

      // Gérer l'événement de connexion au serveur WebSocket
      socket.on('connect', () => {
        // console.log('Connecté au serveur WebSocket');
      });

      // Gérer l'événement de déconnexion du serveur WebSocket
      socket.on('disconnect', () => {
        // console.log('Déconnecté du serveur WebSocket');
      });

      // Écouter l'événement 'doubleAuthDeleted' et mettre à jour la liste de configuration
      socket.on('doubleAuthDeleted', () => {
        this.fetchConfigList();
      });

      // Écouter l'événement 'doubleAuthCreated' et mettre à jour la liste de configuration
      socket.on('doubleAuthCreated', () => {
        this.fetchConfigList();
      });

      // Écouter l'événement 'doubleAuthEdited' et mettre à jour la liste de configuration
      socket.on('doubleAuthEdited', () => {
        this.fetchConfigList();
      });
    },

    async fetchConfigList() {
      const data = await AuthSettings.getAuthSettings();
      this.$store.commit('setConfigList', data);
    },
    async onSwitchChange(id) {
      const myConfig = this.configList.find(config => config._id === id);
      await AuthSettings.editAuthSettings({ data: myConfig });
    }
  },
  async mounted() {
    const data = await AuthSettings.getAuthSettings();
    this.$store.commit("setConfigList", data);
    this.connectSocketIO();
    // Fetch initial configuration list
    this.fetchConfigList();
  },
};
</script>

<style scoped>
.text-content {
  font-weight: 500;
  color: #000000;
}
</style>