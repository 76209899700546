var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"rounded":""}},[_c('span',{staticClass:"text-h5",attrs:{"elevation":"20"}},[_vm._v(_vm._s(_vm.$t('doubleAuth.configuration')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":""},on:{"click":_vm.openModalConfig}},on),[_vm._v(" "+_vm._s(_vm.$t('doubleAuth.créer')))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('doubleAuth.CreateConfiguration')))])])],1),_c('v-card',{staticClass:"mt-4 elevation-5"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('actions.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.configList,"search":_vm.search,"items-per-page":10,"footer-props":{
      'items-per-page-text': _vm.$t('doubleAuth.rows-per-page'),
      'items-per-page-all-text': _vm.$t('doubleAuth.all'),
      }},scopedSlots:_vm._u([{key:"header.double_authentification.data.client",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"header.double_authentification.data.title",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"header.double_authentification.data.bases",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"header.double_authentification.data.twoFactorAuthType",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"header.createdAt",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"header.double_authentification.data.twoFactorAuth",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(("doubleAuth." + (header.text))))+" ")]}},{key:"item.double_authentification.data.client",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTextFirstUp(item.double_authentification.data.client))+" ")]}},{key:"item.double_authentification.data.title",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTextFirstUp(item.double_authentification.data.title))+" ")]}},{key:"item.double_authentification.data.bases",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-height":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.double_authentification.data.bases && item.double_authentification.data.bases.length > 1)?[_vm._v(" "+_vm._s(_vm.$t('doubleAuth.baseSelected'))+" "+_vm._s(item.double_authentification.data.bases.length)+" ")]:(item.double_authentification.data.bases && item.double_authentification.data.bases.length === 1)?[_vm._v(" "+_vm._s(item.double_authentification.data.bases[0])+" ")]:[_vm._v(" "+_vm._s(_vm.$t('doubleAuth.NoBaseSelected'))+" ")]],2)]}}],null,true)},[(item.double_authentification.data.bases && item.double_authentification.data.bases.length > 1)?_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-text-field',{attrs:{"label":"Rechercher une base","hide-details":"","single-line":"","append-icon":"mdi-magnify"},model:{value:(_vm.searchBases),callback:function ($$v) {_vm.searchBases=$$v},expression:"searchBases"}})],1):_vm._e(),_c('v-list',_vm._l((_vm.filteredBases(item.double_authentification.data.bases)),function(base,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(base))])],1)],1)}),1)],1)]}},{key:"item.double_authentification.data.twoFactorAuthType",fn:function(ref){
      var item = ref.item;
return [(item.double_authentification.data.twoFactorAuthType === 'SMS')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v("mdi-message-text")]):_vm._e(),(item.double_authentification.data.twoFactorAuthType ===
          'Email')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v("mdi-gmail")]):_vm._e(),(item.double_authentification.data.twoFactorAuthType ===
          'Cascade')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v("mdi-chart-timeline-variant-shimmer")]):_vm._e(),_vm._v(" "+_vm._s(item.double_authentification.data.twoFactorAuthType)+" "),(!item.double_authentification.data.twoFactorAuthType)?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"warning"}},[_vm._v("mdi-alert-circle")]),_vm._v(" "+_vm._s(_vm.$t('doubleAuth.noChoiceSelected'))+" ")],1):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-calendar-multiple-check")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.double_authentification.data.twoFactorAuth",fn:function(ref){
          var item = ref.item;
return [(item.double_authentification.data.twoFactorAuth)?_c('v-switch',{staticClass:"ma-0",attrs:{"color":"success","hide-details":"","dense":"","label":_vm.$t('doubleAuth.actif')},on:{"change":function($event){return _vm.onSwitchChange(item._id)}},model:{value:(item.double_authentification.data.twoFactorAuth),callback:function ($$v) {_vm.$set(item.double_authentification.data, "twoFactorAuth", $$v)},expression:"item.double_authentification.data.twoFactorAuth"}}):_c('v-switch',{staticClass:"ma-0",attrs:{"color":"primary","hide-details":"","dense":"","label":_vm.$t('doubleAuth.inactif')},on:{"change":function($event){return _vm.onSwitchChange(item._id)}},model:{value:(item.double_authentification.data.twoFactorAuth),callback:function ($$v) {_vm.$set(item.double_authentification.data, "twoFactorAuth", $$v)},expression:"item.double_authentification.data.twoFactorAuth"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openModalUpdate(item._id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.openModalDelete(item._id)}}},[_vm._v("mdi-delete")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.dialogConfig),callback:function ($$v) {_vm.dialogConfig=$$v},expression:"dialogConfig"}},[_c('ConfigAuthentification',{on:{"close-modal":_vm.closeModal,"refresh":_vm.fetchConfigList}})],1),_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.updateConfig),callback:function ($$v) {_vm.updateConfig=$$v},expression:"updateConfig"}},[_c('UpdateConfig',{on:{"close-modal":_vm.closeModalUpdate,"refresh":_vm.fetchConfigList}})],1),_c('v-dialog',{attrs:{"max-width":"25%"},model:{value:(_vm.alertDelete),callback:function ($$v) {_vm.alertDelete=$$v},expression:"alertDelete"}},[_c('DeleteConfig',{on:{"close-modal":_vm.closeModalDelete,"refresh":_vm.fetchConfigList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }