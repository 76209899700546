<template :key="modalKey">
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h3>{{ $t('doubleAuth.EditSettings') }}</h3>  
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelAndClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <h3 class="mx-4 mt-4">{{ $t('doubleAuth.EditConfiguration') }}</h3>

      <v-form>

      </v-form>
      <!-- titre de la configuration -->
      <v-text-field 
        class="mx-4 mt-4" 
        v-model="creatingConfiguration.double_authentification.data.title"
        :label="$t('doubleAuth.ConfigurationTitle')"
        :rules="[titleRules]"
      >
      </v-text-field>

      <v-row>
        <v-col cols="6">
          <v-card-text>
            <!-- Sélection du client -->
            <v-autocomplete 
              v-model="creatingConfiguration.double_authentification.data.client" 
              :items="clientList"
              item-text="name" 
              item-value="name" 
              :label="$t('doubleAuth.client')" 
              :search-input.sync="search"
              @change="updateSelectedClientBases"
              :rules="[clientRules]"
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.name }}
              </template>
            </v-autocomplete>

            <!-- Affichez les sous-bases si une base parent est sélectionnée -->
            <v-autocomplete v-if="creatingConfiguration.double_authentification.data.client" v-model="selectedClientBases"
              :items="baseList" 
              :label="$t('doubleAuth.SubBases')"
              multiple
            >
              <template v-slot:selection="{ item }">
                {{ item }}
              </template>
              <template v-slot:item="{ item }">
                {{ item }}
              </template>
            </v-autocomplete>

            <!-- checbox si on choisi la double authentification -->
            <v-checkbox 
              v-model="creatingConfiguration.double_authentification.data.twoFactorAuth"
              :label="$t('doubleAuth.twoFactorAuth')"
            >
            </v-checkbox>
          </v-card-text>
        </v-col>

        <v-col cols="6">
          <v-card-text>
            <!-- Sélection du type de double authentification -->
            <v-select 
              v-model="creatingConfiguration.double_authentification.data.twoFactorAuthType"
              :items="twoFactorAuthTypes" 
              :label="$t('doubleAuth.AuthType')"
              :rules="[authTypeRules]"
            >
            </v-select>

            <!-- si email pouvoir mettre un textarea pour mettre en forme le mail qui sera envoyé avec tiptap -->
             <!-- si email ajouter une textarea -->
            <v-textarea 
              v-if="creatingConfiguration.double_authentification.data.twoFactorAuthType === 'Email'" 
              v-model="creatingConfiguration.double_authentification.data.msg" 
              rows="2" 
              :label="$t('doubleAuth.SetEmail')"
            >
              {{ creatingConfiguration.double_authentification.data.msg }}
            </v-textarea>
           
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndClose">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="success" @click="save" :disabled="!isFormValid">{{ $t('actions.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import AuthSettings from "@/Services/DoubleAuthentification.js"
import * as Vuex from "vuex";

export default {
  name: "UpdateConfig",
  data() {
    return {
      modalKey: 0,
      clientList: [],
      baseList: [],
      creatingConfiguration: {
        title: "",
        twoFactorAuth: false,
        twoFactorAuthType: "",
        client: "",
        
        double_authentification: {
          data: {
            title: "",
            twoFactorAuth: false,
            twoFactorAuthType: "",
            client: "",
            bases: [],
            msg: "",
          },
        },
      },
      twoFactorAuthTypes: ["Email", "SMS", "Cascade"],
      search: null,
      selectedClientBases: null,
      titleRules: [
        v => !!v || this.$t('doubleAuth.TitleRequired'),
      ],
      clientRules: [
        v => !!v || this.$t('doubleAuth.clientRequired'),
      ],
      authTypeRules: [
        v => !!v || this.$t('doubleAuth.twoFactorRequired'),
      ],
    };
  },
  computed: {
    ...Vuex.mapMutations(["setConfigList"]),
    ...Vuex.mapState(["configList", "selectedConfig", "informations"]),
    isFormValid() {
      return (
        !!this.creatingConfiguration.double_authentification.data.title &&
        !!this.creatingConfiguration.double_authentification.data.client &&
        !!this.creatingConfiguration.double_authentification.data.twoFactorAuthType &&
        !!this.selectedClientBases
      );
    },
  },
  // call fetchdata every time the component is mounted
  watch: {
    selectedConfig: function () {
      this.fetchData();
    },
    // verifier modalKey pour recharger les données
    modalKey: function () {
      this.fetchData();
    },
  },
  methods: {
    cancelAndClose() {
      this.modalKey += 1;
      this.$emit("close-modal");
    },
    reset() {
      this.creatingConfiguration = {
        title: "",
        twoFactorAuth: false,
        twoFactorAuthType: "",
        client: "",
        double_authentification: {
          data: {
            title: "",
            twoFactorAuth: false,
            twoFactorAuthType: "",
            client: "",
            bases: [],
            msg: "",
          },
        },
      };
      this.clientRules = [
        v => !!v || this.$t('doubleAuth.clientRequired'),
      ];
      this.authTypeRules = [
        v => !!v || this.$t('doubleAuth.twoFactorRequired'),
      ];
      this.titleRules = [
        v => !!v || this.$t('doubleAuth.TitleRequired'),
      ];
    },
    async updateSelectedClientBases() {
      // afficher les bases du client selectionné dispo dans this.creatingConfiguration
      if (this.creatingConfiguration.double_authentification.data.client) {
        for (let i in this.clientList) {
          if (this.clientList[i].name === this.creatingConfiguration.double_authentification.data.client) {
            this.baseList = this.clientList[i].bases;
          }
          // alimenter le tableau des bases selectionnées
          this.selectedClientBases = this.creatingConfiguration.double_authentification.data.bases;
        }
      } else {
        this.selectedClientBases = [];
      }
    },
    async save() {
      // ajouter les sous bases selectionnées dans la configuration
      this.creatingConfiguration.double_authentification.data.bases = this.selectedClientBases;

      await AuthSettings.editAuthSettings({data: this.creatingConfiguration});

      // this.reset();
      this.$emit("refresh");
      this.$emit("close-modal");
    },
    async fetchData() {
      this.clientList = await PlaneteOnlineService.getBases();
      this.creatingConfiguration = await AuthSettings.getAuthSettingsById(this.$store.state.selectedConfig);
      this.updateSelectedClientBases();
    }
  },
  async mounted() {
    this.fetchData();
    this.modalKey++;
  },
};
</script>

<style scoped></style>
