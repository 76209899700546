<template :key="modalKey">
  <div>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          <h3>{{ $t('doubleAuth.settings') }}</h3> 
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancelAndClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <h3 class="mx-4 mt-4">Configuration</h3>

      <!-- titre de la configuration -->
      <v-text-field 
        class="mx-4 mt-4" 
        v-model="creatingConfiguration.title"
        :label="$t('doubleAuth.ConfigurationTitle')"
        :rules="[titleRules]"
        ></v-text-field>

      <v-row>
        <v-col cols="6">
          <v-card-text>
            <!-- Sélection du client -->
            <v-autocomplete v-model="creatingConfiguration.client" :items="clientList" 
            item-text="name" 
            item-value="name"
            :label="$t('doubleAuth.client')" 
            :search-input.sync="search" 
            @change="updateSelectedClientBases"
            :rules="[clientRules]"
          >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.name }}
              </template>
            </v-autocomplete>

            <!-- Affichez les sous-bases si une base parent est sélectionnée -->
            <v-autocomplete 
              v-if="creatingConfiguration.client" v-model="selectedClientBases"
              :items="baseList"
              :label="$t('doubleAuth.SubBases')"
              multiple  
              >
              <template v-slot:selection="{ item }">
                {{ item }}
              </template>
              <template v-slot:item="{ item }">
                {{ item }}
              </template>
            </v-autocomplete>
            <!-- checbox si on choisi la double authentification -->
            <v-checkbox 
              v-model="creatingConfiguration.twoFactorAuth" 
              :label="$t('doubleAuth.twoFactorAuth')"
            >
            </v-checkbox>
          </v-card-text>
        </v-col>

        <v-col cols="6">
          <v-card-text>
            <!-- Sélection du type de double authentification -->
            <v-select 
              v-model="creatingConfiguration.twoFactorAuthType" 
              :items="twoFactorAuthTypes"
              :label="$t('doubleAuth.AuthType')"
              rows="2"
              :rules="[authTypeRules]"
              >
            </v-select>
            <!-- si email ajouter une textarea -->
            <v-textarea
              v-if="creatingConfiguration.twoFactorAuthType === 'Email'"
              v-model="creatingConfiguration.msg" 
              :label="$t('doubleAuth.SetEmail')"
              rows="2"
              >
            </v-textarea>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelAndClose">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="success" @click="save" :disabled="!isFormValid" >{{ $t('doubleAuth.créer') }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogError" max-width="55%">
      <v-card> 
        <v-alert class="mb-0"
          v-if="baseName.length > 1"
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
        >
          Bases déjà existantes, veuillez sélectionner une autre base ou modifier la configuration existante :
          <ul>
            <li v-for="base in baseName" :key="base">{{ base }}</li>
          </ul>

        </v-alert>
        <v-alert class="mb-0"
          v-else
          text
          prominent
          type="error"
          icon="mdi-cloud-alert"
        >
          La base {{ baseName.flat().join(' ') }} existe déjà avec une autre configuration, veuillez sélectionner une autre base ou modifier la configuration existante.
        </v-alert>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import AuthSettings from "@/Services/DoubleAuthentification.js"
import * as Vuex from "vuex";

export default {
  name: "ConfigAuthentification",
  data() {
    return {
      modalKey: 0,
      dialogError: false,
      clientList: [],
      baseList: [],
      creatingConfiguration: {
        title: "",
        twoFactorAuth: false,
        twoFactorAuthType: "",
        // verificationCode: "",
        msg: "",
        client: "",
        bases: [],
      },
      baseName: [],
      twoFactorAuthTypes: ["Email", "SMS", "Cascade"],
      search: null,
      selectedClientBases: null,
      titleRules: [
        v => !!v || this.$t('doubleAuth.TitleRequired'),
      ],
      clientRules: [
        v => !!v || this.$t('doubleAuth.clientRequired'),
      ],
      authTypeRules: [
        v => !!v || this.$t('doubleAuth.twoFactorRequired'),
      ],
    };
  },
  computed: {
    ...Vuex.mapState(["informations", "configList"]),
    // tous les champs requis sont remplis
    isFormValid() {
      return (
        !!this.creatingConfiguration.title &&
        !!this.creatingConfiguration.client &&
        !!this.creatingConfiguration.twoFactorAuthType &&
        !!this.selectedClientBases
      );
    }, 
    existingBaseConfigs() {
      return this.configList.filter(config =>
        config.double_authentification.data.bases.some(base =>
          this.creatingConfiguration.bases.includes(base)
        )
      );
    },
  },
  methods: {
    cancelAndClose() {
      this.reset();
      this.$emit("close-modal");
    },
    reset() {
      this.creatingConfiguration = {
        title: "",
        twoFactorAuth: false,
        twoFactorAuthType: "",
        client: "",
        bases: [],
        };
        this.clientRules = [
          v => !!v || this.$t('doubleAuth.clientRequired'),
        ];
        this.authTypeRules = [
          v => !!v || this.$t('doubleAuth.twoFactorRequired'),
        ];
        this.titleRules = [
          v => !!v || this.$t('doubleAuth.TitleRequired'),
        ];
        this.modalKey += 1;
    },
    async updateSelectedClientBases() {
      if (this.creatingConfiguration.client) {
        for (let i in this.clientList) {
          if (this.clientList[i].name === this.creatingConfiguration.client) {
            this.baseList = this.clientList[i].bases;
          }
        }
      } else {
        this.selectedClientBases = [];
      }
    },
    async save() {
      // Ajoutez les sous-bases sélectionnées dans la configuration
      this.creatingConfiguration.bases = this.selectedClientBases;

        if (this.existingBaseConfigs.length > 0) {
          // Affichez les bases déjà existantes dans la modal d'erreur
          this.baseName = this.existingBaseConfigs.flatMap(config =>
            config.double_authentification.data.bases
          ); 
          // afficher que les bases doublons selectionnées dans le select en comparant avec this.selectedClientBases
          let allBases = this.baseName.flat();
          let selectedBases = this.selectedClientBases;
          let duplicateBases = allBases.filter((base) => selectedBases.includes(base));
          this.baseName = duplicateBases;
          
          this.dialogError = true;
          return;
        } else {
        // Enregistrez la nouvelle configuration
        await AuthSettings.savetAuthSettings({data: this.creatingConfiguration});
        this.$emit("refresh");
        this.$emit("close-modal");
        this.reset();
        this.baseName = [];
        this.dialogError = false;
      }
    },
  },
  created() {
    this.reset();
  },
  async mounted() {
    this.clientList = await PlaneteOnlineService.getBases();

  },
};
</script>

<style scoped>
</style>
